import React, { Component } from "react";
import {
    Card,
    Grid,
    Button, CircularProgress, withStyles
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import CustomizedSnackbar from "../../shared/components/CustomizedSnackbar";
import {withTranslation} from "react-i18next";
import ReactFlagsSelect from 'react-flags-select';
import spvTokenService from "../../shared/services/spvTokenService";
import i18n from "i18next";

const styles = () => ({
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    logo: {
        height: "250px"
    }
});

class ConnectSpvToken extends Component {
    state = {
        link: "",
        spvCallback: "",
        selectedLanguage: '',
        successMessage: null,
        errorMessage: null,
        loading: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.match.params.token) {
            this.setState({
                ...this.state,
                link: this.props.match.params.token,
                selectedLanguage: this.props.i18n.language === 'en' ? 'GB' : this.props.i18n.language.toUpperCase()
            });
        }
    }

    handleFormSubmit = () => {
        this.setState({
                ...this.state,
                loading: true
            }, () => {
            if(this.props.ownToken){
                spvTokenService.handleLoginConnectSpvWithOwnToken().then(res => {
                    console.log("res: ", res)
                    this.setState({
                            ...this.state,
                            spvCallback: res,
                            loading: false
                        }, ()=> i18n.t('eInvoice.token.authorized')
                    )
                }).catch(err => {
                    console.log("err: ", err)
                    this.setState({
                        ...this.state,
                        loading: false,
                        errorMessage: err.message
                    })
                });
            }
            else {
                spvTokenService.handleLoginConnectSpv(this.state.link).then(res => {
                    console.log("res: ", res)
                    this.setState({
                            ...this.state,
                            spvCallback: res,
                            loading: false
                        }, ()=> i18n.t('eInvoice.token.authorized')
                    )
                }).catch(err => {
                    console.log("err: ", err)
                    this.setState({
                        ...this.state,
                        loading: false,
                        errorMessage: err.message
                        // i18n.t('eInvoice.token.unconnected')
                    })
                });
            }
            }
        )
    };

    handleLanguageClick = (lng) => {
        const {i18n} = this.props;
        i18n.changeLanguage(lng).then();
    };

    renderLanguage = () => {
        return (
            <ReactFlagsSelect
                countries={["RO", "GB"]}
                showOptionLabel={false}
                showSelectedLabel={false}
                defaultCountry="RO"
                selected={this.state.selectedLanguage}
                selectedSize={30}
                optionsSize={30}
                onSelect={this.onSelectFlag}
                selectButtonClassName="language-signup"
            />
        );
    };

    onSelectFlag = (countryCode) => {
        switch(countryCode) {
            case 'RO':
                this.handleLanguageClick('ro');
                this.setState({
                    ...this.state,
                    selectedLanguage: 'RO'
                });
                break;
            case 'GB':
                this.handleLanguageClick('en');
                this.setState({
                    ...this.state,
                    selectedLanguage: 'GB'
                });
                break;
            default:
                this.handleLanguageClick('ro');
                this.setState({
                    ...this.state,
                    selectedLanguage: 'RO'
                });
                break;
        }
    };

    render() {
        let { link, loading } = this.state;
        let { classes, t } = this.props;

        return (
            <div>
                {!this.props.ownToken && <div style={{float: 'right !important', position: 'absolute', right: '7%', top:'21%'}}>
                    {this.renderLanguage()}
                </div>}
                <div className="signup flex flex-center w-100 h-100vh">
                    <div className="p-8">
                        <Card className="signup-card position-relative y-center">
                            <Grid container>
                                <Grid item lg={5} md={5} sm={5} xs={12}>
                                    <div className="p-32 flex flex-center bg-light-gray flex-middle h-100">
                                        <img src="/assets/logo/Logo-Expert-Sport-Club-Outlined-02.svg" alt="" className={classes.logo}/>
                                    </div>
                                </Grid>
                                <Grid item lg={7} md={7} sm={7} xs={12}>
                                    <div className="p-36 h-100">
                                        <div className="mb-16 w-100"><h3>{t('eInvoice.connectSpvTitle')}</h3></div>
                                        <div className="mb-16 w-100"><h6>{t('eInvoice.token.startConnection')}</h6></div>
                                        <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                            {!this.props.ownToken && <TextValidator
                                                className="mb-24 w-100"
                                                variant="outlined"
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                                type="text"
                                                name="link"
                                                value={link}
                                            />}
                                            <div className="flex flex-middle">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    fullWidth
                                                    disabled={this.state.spvCallback}
                                                >
                                                    {t('eInvoice.connectSpv')}
                                                    {loading && (
                                                        <CircularProgress
                                                            size={24}
                                                            className={classes.buttonProgress}
                                                        />
                                                    )}
                                                </Button>
                                            </div>
                                        </ValidatorForm>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            style={{marginTop: "10px"}}
                                            disabled={!this.state.spvCallback}
                                            // onClick={}
                                        >
                                            <a href={this.state.spvCallback}
                                               target="_blank"
                                               rel="noopener noreferrer">{t('eInvoice.getTokenButton')}
                                            </a>
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                        {this.state.errorMessage &&
                            <CustomizedSnackbar message={this.state.errorMessage} severity="error" onClose={() => this.setState({...this.state, errorMessage: null})}/>}
                        {this.state.successMessage &&
                            <CustomizedSnackbar message={this.state.successMessage} severity="success"   onClose={() => this.setState({...this.state, successMessage: null})}/>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    link: state.link,
    selectedLanguage: state.selectedLanguage
});

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            {}
        )(withTranslation()(ConnectSpvToken))
    )
);

import moment from "moment";

class TrainingMobile {
    constructor(data) {
        this.id = data.id;

        this.location = data.location;
        this.locationId = data.locationId;
        this.trainingDate = data.trainingDate ? new Date(data.trainingDate) : null;

        this.timeStart = data.timeStart;
        this.timeStop = data.timeStop;

        this.teamId = data.teamId;
        this.teamName = data.teamName;
        this.color = data.color;
        this.title = data.title;

        this.start = this.getDate(this.timeStart ? this.timeStart : this.timeStart);
        this.end = this.getDate(this.timeStop ? this.timeStop : this.timeStop);

        this.description = data.description;
        this.comments = data.comments;
        this.dateStart = data.dateStart;
        this.dateStop = data.dateStop;
        this.videoStreaming = data.videoStreaming;
        this.daysOfWeek = data.daysOfWeek;
        this.coaches = data.coaches;
        this.athletes = data.athletes;
        this.canceled = data.canceled;
        this.athletesStats = data.athletesStats;
        this.lastModified = data.lastModified;
        this.created = data.created;
        this.createdByFullName = data.createdByFullName;
        this.lastModifiedByFullName = data.lastModifiedByFullName;
        this.trainingPlanId = data.trainingPlanId;

        this.individual = data.individual;
        this.color = data.color;
        this.cost = data.cost;
        this.currency = data.currency;
    }

    getDate(time) {
        let hours = parseInt(time.split(":")[0]);
        const minutes = parseInt(time.split(":")[1]);
        const date = new Date(moment(this.trainingDate).utc().format('YYYY-MM-DD'));
        if (time.includes('pm')){
            hours+=12;
        }
        date.setHours(hours, minutes);
        return date;
    }

}

export default TrainingMobile;

import emailService from "../../shared/services/emailService";
import {EUserType} from "../../shared/enums/EUserType";
import i18n from "../../../i18n";
import {EResponseCodes} from "../../shared/enums/ERespondeCodes";
import {ERole} from "../../shared/enums/ERole";
import {userUtilsService} from "../../shared/services/userUtilsService";

export const EMAIL_ERROR = "EMAIL_ERROR";
export const EMAIL_SUCCESS = "EMAIL_SUCCESS";
export const EMAIL_LOADING = "EMAIL_LOADING";
export const ERASE_MESSAGE = "ERASE_MESSAGE";

export function sendInvitation(senderId, senderRole, email, firstName, lastName, clubId, role, selectedAthletes, selectedTeams, selectedSport, selectedSubscriptionType, language) {
    return dispatch => {
        if (role === EUserType.parent && selectedAthletes.length === 0){
            dispatch({
                type: EMAIL_ERROR,
                data: i18n.t('invitation.error.noAthlete')
            });
            return;
        }

        //(role === EUserType.coach || role === EUserType.athlete)
        if ((userUtilsService.getCoachUserType().includes(role) || userUtilsService.getAthleteUserType().includes(role)) && selectedTeams.length === 0) {
            dispatch({
                type: EMAIL_ERROR,
                data: i18n.t('invitation.error.noTeam')
            });
            return;
        }

        //role === EUserType.mainCoach
        if (userUtilsService.getMainCoachUserType().includes(role) && selectedSport === null) {
            dispatch({
                type: EMAIL_ERROR,
                data: i18n.t('invitation.error.noSport')
            });
            return;
        }

        dispatch({
            type: EMAIL_LOADING
        });

        return emailService
            .invite({ senderId, senderRole, email, firstName, lastName, clubId, role, athletes: selectedAthletes, teams: selectedTeams, sport: selectedSport, subscriptionType: selectedSubscriptionType, language: language })
            .then((res) => {
                const successMessage = i18n.t(EResponseCodes[res.message]);
                dispatch({
                    type: EMAIL_SUCCESS,
                    data: successMessage
                });
                return Promise.resolve();
            })
            .catch((error) => {
                const errorMessage = i18n.t(EResponseCodes[error.message]);
                dispatch({
                    type: EMAIL_ERROR,
                    data:errorMessage
                });
                return Promise.reject(errorMessage);
            });
    };
}


export function sendReminderForRegistration(properties) {
    return dispatch => {
        dispatch({
            type: EMAIL_LOADING
        });

        properties.role = userUtilsService.getUserTypeForRole(properties.role);

        // switch(properties.role) {
        //     case(ERole.ROLE_MAINCOACH):
        //         properties.role = EUserType.mainCoach;
        //         break;
        //     case(ERole.ROLE_COACH):
        //         properties.role = EUserType.coach;
        //         break;
        //     case(ERole.ROLE_PARENT):
        //         properties.role = EUserType.parent;
        //         break;
        //     case(ERole.ROLE_ATHLETE):
        //         properties.role = EUserType.athlete;
        //         break;
        //     default:
        //         break;
        // }

        return emailService
            .sendReminder(properties)
            .then((res) => {
                const successMessage = i18n.t(EResponseCodes[res.message]);
                dispatch({
                    type: EMAIL_SUCCESS,
                    data: successMessage
                });
                return Promise.resolve(successMessage);
            })
            .catch((error) => {
                const errorMessage = i18n.t(EResponseCodes[error.message]);
                dispatch({
                    type: EMAIL_ERROR,
                    data:errorMessage
                });
                return Promise.reject(errorMessage);
            });
    };
}

export function sendForgotPasswordEmail({email, language}) {
    return dispatch => {
        dispatch({
            type: EMAIL_LOADING
        });

        emailService
            .sendForgotPasswordEmail({email, language})
            .then((res) => {
                return dispatch({
                    type: EMAIL_SUCCESS,
                    data: i18n.t(EResponseCodes[res.message])
                });
            })
            .catch(error => {
                return dispatch({
                    type: EMAIL_ERROR,
                    data: i18n.t(EResponseCodes[error.message])
                });
            });
    };
}
export function generateSpvTokenLinkEmail(link) {
    return dispatch => {
        dispatch({
            type: EMAIL_LOADING
        });

        emailService
            .generateSpvTokenLinkEmail(link)
            .then((res) => {
                return dispatch({
                    type: EMAIL_SUCCESS,
                    data: i18n.t(EResponseCodes[res.message])
                });
            })
            .catch(error => {
                return dispatch({
                    type: EMAIL_ERROR,
                    data: i18n.t(EResponseCodes[error.message])
                });
            });
    };
}

export function eraseMessage() {
    return dispatch => {
        dispatch({
            type: ERASE_MESSAGE
        });
    };
}

import {trainingRepositoryMobile} from "../repositories/trainingRepositoryMobile";
import TrainingMobile from "../models/club/TrainingMobile";

class TrainingServiceMobile {

    getTrainings = (start, end, sportId, teamId, athleteId, individual) => {
        return trainingRepositoryMobile.getTrainings(start, end, sportId, teamId, athleteId, individual)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new TrainingMobile(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getTrainingById = (id) => {
        return trainingRepositoryMobile.getTrainingById(id)
            .then((res) => {
                if (res) {
                    return new TrainingMobile(res);
                }
                return Promise.reject();
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateTrainingProperties = (trainingRequest) => {
        return trainingRepositoryMobile.updateTrainingProperties(trainingRequest).then(res => {
            if (res) {
                return res;
            }
            return Promise.reject();
        })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateUpcomingTrainingsProperties = (trainingRequest) => {
        return trainingRepositoryMobile.updateUpcomingTrainingsProperties(trainingRequest);
    };

    deleteUpcomingTrainings = (training) => {
        return trainingRepositoryMobile.deleteUpcomingTrainings(training);
    };

}

export default new TrainingServiceMobile();

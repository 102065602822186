import React, {Component} from "react";
import {Button, Card, CircularProgress, Icon, IconButton, MenuItem, withStyles,} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import {addExtraPaymentNotice, eraseMessage} from "../../redux/actions/ExtraPaymentNoticeActions";
import CustomizedSnackbar from "../../shared/components/CustomizedSnackbar";
import CurrentUser from "../../shared/models/CurrentUser";
import Loader from "react-loader-spinner";
import {Breadcrumb, ConfirmationDialog} from "../../../egret";
import {withTranslation} from "react-i18next";
import {ECurrency} from "../../shared/enums/ECurrency";
import {themeColors} from "../../EgretLayout/EgretTheme/themeColors";
import Typography from "@material-ui/core/Typography";
import DateModifiedDetails from "../../shared/components/DateModifiedDetails";
import HelpInfo from "../../shared/components/HelpInfo";
import Auditable from "../../shared/models/core/Auditable";
import i18n from "../../../i18n";
import MandatoryLabel from "../../shared/components/MandatoryLabel";
import {classList} from "../../../utils";
import extraPaymentService from "../../shared/services/extraPaymentService";
import ExtraPaymentNotice from "../../shared/models/club/ExtraPaymentNotice";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {enGB, ro} from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FragmentList from "../../shared/components/FragmentList";
import AddDialog from "../../shared/components/AddDialog";
import athleteServiceMobile from "../../shared/services/athleteServiceMobile";
import {EUserStatus} from "../../shared/enums/EUserStatus";

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  wrapper: {
    marginTop: 50,
    position: "relative",
    textAlign: "center"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  loader: {
    position:"absolute",
    marginTop: "30vh",
    marginLeft:"37vw"
  }
});

const columns = ['form.lastName', 'form.firstName', 'form.email', 'form.phone'];
const topFields = ["lastName", "firstName"];
const bottomFields = ["email", "phone"];


class PaymentNoticeDetails extends Component {
  _isMounted = false;
  state = {
    extraPaymentNotice: {
      fee: 0,
      currency: ECurrency.RON,
      date: new Date(),
      deadline: new Date()
    },
    loading: true,
    currentUser: null,
    club: null,
    shouldShowAthletesDialog: false,
    shouldOpenConfirmationDialog: false,
    athletes: [],
    selectedAthletes: [],
    athletesRetrieved: false
  };

  componentDidMount() {
    this._isMounted = true;
    if (!this.isNewExtraPaymentNotice()) {
        extraPaymentService.getExtraPaymentNoticeById(this.props.match.params.id).then(res => {
          const extraPaymentNotice = new ExtraPaymentNotice(res);
          this.setState({
            ...this.state,
            extraPaymentNotice,
            currentUser: new CurrentUser(this.props.user),
            loading: false
          });
        });
    }
    else {
      this.setState({
        ...this.state,
        currentUser: new CurrentUser(this.props.user),
        loading: false
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  isNewExtraPaymentNotice() {
    return this.props.match.params.id === "add";
  };

  handleChange = event => {
    event.persist();
    this.setState({
      ...this.state,
      extraPaymentNotice: {...this.state.extraPaymentNotice, [event.target.name]: event.target.value }
    });
  };

  handleDeadlineChange = date => {
    const extraPaymentNotice = this.state.extraPaymentNotice;
    if(date >= extraPaymentNotice.date) {
      extraPaymentNotice.deadline = date;
      this.setState({
        ...this.state,
        extraPaymentNotice
      });
    }
  };

  handleSubmit = () => {
    this.setState({
      shouldOpenConfirmationDialog: true
    });
  };

  handleValidationErrors = errors => {
     document.getElementById(errors[0].props.name).focus();
  };

  isDisabled = user => {
    return user ? !(user.isClubPresidentManagerRole() || user.isClubMainCoachRole() || user.isClubCoachTeamRole()) : true;
  };

  isSaveDisabled = () => {
    const {extraPaymentNotice, selectedAthletes} = this.state;
    return  extraPaymentNotice && extraPaymentNotice.description && extraPaymentNotice.fee > 0  && selectedAthletes.length > 0 ? false : true;
  }

  // getAuditableDetails = () => {
  //   if(this.state.extraPaymentNotice)
  //     return new Auditable(this.state.extraPaymentNotice);
  //   return {};
  // };
  //
  getHelpDetails = () => {
    return {
      content: i18n.t("help.extraPaymentNotice")
    };
  };

  getTitle() {
    const {t} = this.props;
    return  t('extraPaymentNotice.details') ;
  }

  goBack(){
    this.props.location.state ? this.props.history.push({pathname: this.props.location.state.path, state: {query: this.props.location.state.query}}) : this.props.history.goBack();
  }

  handleViewClick = (id) => {
    this.props.history.push(`/athlete/${id}`);
  };

  openAddDialog = () => {
    this.setState({
      shouldShowAthletesDialog: true
    });
  };

  handleDialogClose = () => {
    this.setState({
      shouldShowAthletesDialog: false
    });
  };

  handleSubmitDialogClose = () => {
    this.setState({
      shouldOpenConfirmationDialog: false,
    });
  };

  handleConfirmationResponse = () => {
    const athleteIds = [];
    this.state.selectedAthletes.forEach(athlete => {
      athleteIds.push(athlete.id);
    })

    this.setState({
      ...this.state,
      loading: true
    }, () => {
      this.handleSubmitDialogClose();
      this.props.addExtraPaymentNotice(this.state.extraPaymentNotice, athleteIds).then(() => {
        this.setState({
          ...this.state,
          loading: false
        }, () => {
          this.goBack();
        });
      }).catch(error =>{
        this.setState({
          ...this.state,
          loading: false
        })
      });
    });

  };

  getAthletes() {
    this.setState({
      athletes: [],
      athletesRetrieved: false
    });
    athleteServiceMobile.getClubActiveAthletes().then(( athletes ) => {
      this.checkIfAthletesAreSelected(athletes);
    });
  };

  checkIfAthletesAreSelected = athletes => {
    const selectedAthletes = this.state.selectedAthletes;
    if (selectedAthletes) {
      selectedAthletes.forEach((selectedAthlete) => {
        athletes = athletes.filter(athlete => athlete.id !== selectedAthlete.id);
      });
    }
    this.setState({
      athletes: athletes ? athletes.sort((a,b) => a.lastName.localeCompare(b.lastName)) : [],
      athletesRetrieved: true
    });
  };

  handleConfirmationRemoveAthletesResponse = (selectedAthletesToRemove) => {
    if (selectedAthletesToRemove.length !== 0) {
      let tempAthletes = this.state.selectedAthletes;
      tempAthletes = this.elementsToRemove(selectedAthletesToRemove, tempAthletes);
      this.setState({
        ...this.state,
        selectedAthletes: tempAthletes
      })
    }
  };

  elementsToRemove = (itemIdsToRemove, list) => {
    itemIdsToRemove.forEach((itemIdToRemove ) => {
      list = list.filter(item => item.id !== itemIdToRemove);
    });
    return list;
  };

  addAthletes = (selectedAthletes) => {
    let allSelectedAthletes = this.state.selectedAthletes;
    if (selectedAthletes.length !== 0) {
        allSelectedAthletes = allSelectedAthletes.concat(selectedAthletes);
      this.setState({
        ...this.state,
        selectedAthletes: allSelectedAthletes,
        shouldShowAthletesDialog: false
      })
    }

  };

  render() {

    let {
      description,
      fee,
      currency,
      date,
      deadline
    } = this.state.extraPaymentNotice;

    const {currentUser, extraPaymentNotice, loading, shouldShowAthletesDialog, shouldOpenConfirmationDialog, selectedAthletes  } = this.state;

    let {classes, t} = this.props;

    return (
      !this.isNewExtraPaymentNotice() && extraPaymentNotice.id === undefined ? (
        <Loader
          className = {classes.loader}
          type="Oval"
          color={themeColors.purple1.palette.primary.main}
          height={100}
          width={100}
        />
      ) : (
        <div className="m-sm-30">
          <div  className="mb-sm-30">
            <Breadcrumb
              routeSegments={[
                { name: t('extraPaymentNotice.title'), path: "/paymentNotice/list" },
                {name: this.getTitle()}
                ]}
            />
          </div>
          <Card elevation={6}>
            <div className="invoice-viewer py-16">
              <div className="viewer_actions  flex flex-space-between flex-middle pl-16 pr-8 py-8 bg-primary fixed-content" id="header">
                <IconButton onClick={() => this.goBack()}>
                  <Icon  className="text-white">arrow_back</Icon>
                </IconButton>
                <h4 className="mb-16 text-white">{this.getTitle()}</h4>
                <div className="flex flex-space-between flex-middle">
                    {/*<DateModifiedDetails*/}
                    {/*    auditableDetails={this.getAuditableDetails()}/>*/}
                  <HelpInfo
                      helpDetails={this.getHelpDetails()}/>
                </div>
              </div>
              <br></br>

              <div id="print-area">
                <div className="viewer__order-info px-16 mb-32">

                  <ValidatorForm
                      ref="form"
                      onSubmit={this.handleSubmit}
                      onError={errors => this.handleValidationErrors(errors)}
                  >
                    <Grid container spacing={6}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div style={{display: "flex"}}>
                          {(description === undefined ||
                              description.replace(/\s/g, '') === '' ) &&
                          (<MandatoryLabel />)}

                          <TextValidator
                              className="mb-16 w-100"
                              label={this.isDisabled(currentUser) ? <Typography className={"ui-state-disabled"}>{t("extraPaymentNotice.description")}</Typography>
                                  : t("extraPaymentNotice.description")}
                              onChange={this.handleChange}
                              type="text"
                              name="description"
                              value={description || ''}
                              validators={["required"]}
                              errorMessages={[t('validation.error.required')]}
                              id="name"
                              disabled={this.isDisabled(currentUser)}
                              InputProps={{ className: "ui-state-disabled" }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextValidator
                            className="mb-16 w-100"
                            label={t('extraPaymentNotice.fee')}
                            onChange={this.handleChange}
                            type="text"
                            name="fee"
                            value={fee}
                            validators={[
                              "required",
                              "matchRegexp:^(?:[1-9]\\d*|0)?(?:\\.\\d+)?$"
                            ]}
                            errorMessages={[t('validation.error.required'), t('validation.error.double')]}
                            id="fee"
                            disabled={this.isDisabled(currentUser)}
                            InputProps={{ className: "ui-state-disabled" }}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextValidator
                            className="mb-16 w-100"
                            label={t('extraPaymentNotice.currency')}
                            select
                            name="currency"
                            value={currency || ''}
                            validators={["required"]}
                            errorMessages={[t('validation.error.required')]}
                            onChange={this.handleChange}
                            fullWidth
                            disabled= {this.isDisabled(currentUser)}
                            InputProps={{ className: "ui-state-disabled" }}
                        >
                          {Object.entries(ECurrency).map(([key,value],i) => (
                              <MenuItem key={key} value={value}>
                                {value}
                              </MenuItem>
                          ))}
                        </TextValidator>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                          <MuiPickersUtilsProvider locale={i18n.language === 'en' ? enGB : ro}
                                                   utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required={false}
                                className="w-100"
                                margin="none"
                                id="mui-pickers-date"
                                label={ <Typography
                                    className={"ui-state-disabled"}>{t("extraPaymentNotice.date")}</Typography>
                                }
                                inputVariant="standard"
                                type="text"
                                autoOk={true}
                                value={date || null}
                                format="dd-MM-yyyy"
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                disabled={true}
                                InputProps={{className: "ui-state-disabled"}}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                          <MuiPickersUtilsProvider locale={i18n.language === 'en' ? enGB : ro}
                                                   utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required={true}
                                className="w-100"
                                margin="none"
                                id="mui-pickers-date"
                                label={this.isDisabled(currentUser) ? <Typography
                                        className={"ui-state-disabled"}>{t("extraPaymentNotice.deadline")}</Typography>
                                    : t("extraPaymentNotice.deadline")}
                                inputVariant="standard"
                                type="text"
                                autoOk={true}
                                value={deadline || null}
                                onChange={this.handleDeadlineChange}
                                format="dd-MM-yyyy"
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                disabled={this.isDisabled(currentUser)}
                                InputProps={{className: "ui-state-disabled"}}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <div className="py-20">
                      <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            {t("menu.athletes")} : { selectedAthletes.length > 0 ? selectedAthletes.length : '0'}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{display:"block"}} onClick={(event) => event.stopPropagation()}>
                          <FragmentList
                              list = {selectedAthletes}
                              columns = {columns}
                              topFields = {topFields}
                              bottomFields = {bottomFields }
                              showCheckbox = {!this.isDisabled(currentUser)}
                              showActions = {!this.isDisabled(currentUser)}
                              showActionColumn = {false}
                              showMoreDetails = {!this.isDisabled(currentUser)}
                              withoutConfirmation={true}
                              emptyListMessage = {t("parent.noAthletes")}
                              addButtonLabel = {t('team.labels.addAthletes')}
                              removeButtonLabel = {t('team.labels.removeAthletes')}
                              removeConformationText = {t('extraPaymentNotice.confirmation.deleteAthletes')}
                              isRemoveActive={!this.isDisabled(currentUser)}
                              openAddDialog = {() => this.openAddDialog('athlete')}
                              handleViewClick = {(id) => !this.isDisabled(currentUser) ? this.handleViewClick('athlete', id) : null}
                              handleConfirmationRemoveResponse = {this.handleConfirmationRemoveAthletesResponse}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    { loading &&  (<div className={classes.wrapper}><CircularProgress /> </div>)}
                    {!this.isDisabled(currentUser) && !loading && (
                        <div className={classes.wrapper}>
                          <Button
                              variant="contained"
                              color="primary"
                              disabled={this.isSaveDisabled()}
                              type="submit"
                          >
                            <span className="capitalize">{t('form.actions.generate')}</span>
                            {(loading) && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                          </Button>
                        </div>
                    )}
                  </ValidatorForm>
                </div>
              </div>
            </div>
            <CustomizedSnackbar message={extraPaymentNotice.error} severity="error" onClose={this.props.eraseMessage}/>
            {shouldShowAthletesDialog && (
                <AddDialog
                    list = {this.state.athletes}
                    listRetrieved = {this.state.athletesRetrieved}
                    title = {t('athlete.title')}
                    searchPlaceholder = {t('table.head.user.lastName')}
                    searchByField = {'lastName'}
                    label = {'fullName'}
                    small = {'email'}
                    emptyMessage = {t('team.noAthletes')}
                    getList = {this.getAthletes.bind(this)}
                    handleClose={this.handleDialogClose}
                    handleAddAction = {this.addAthletes}
                    open={shouldShowAthletesDialog}
                />
            )}
            {shouldOpenConfirmationDialog && (
                <ConfirmationDialog
                    open={shouldOpenConfirmationDialog}
                    onConfirmDialogClose={this.handleSubmitDialogClose}
                    onYesClick={this.handleConfirmationResponse}
                    text={t('extraPaymentNotice.confirmation.generate', {"name": this.state.extraPaymentNotice.description})}
                />
            )}

          </Card>
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  saveExtraPaymentNotice: PropTypes.func.isRequired,
  extraPaymentNotice: state.extraPaymentNotice,
  user: state.user
});

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            {  addExtraPaymentNotice, eraseMessage}
        )(withTranslation()(PaymentNoticeDetails)))
);

import Notice from "./Notice";

class ExtraPaymentNotice extends Notice{
    constructor(data) {
        super(data);
        if (data) {
            this.date = new Date(data.date);
            this.description = data.description;
            this.fee = data.fee;
            this.currency = data.currency;
            this.donation = data.donation;
            this.extraProperties = data.extraProperties;
        }
    }
}

export default ExtraPaymentNotice;

import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

class EmailService {

    invite = async (properties) => {
        return FetchAdapter.request(`${APP_CONFIG.URL.email}/invite`, {
            method: 'POST',
            body: JSON.stringify(properties)
        });
    };

    sendForgotPasswordEmail = async ({email, language}) => {
        return FetchAdapter.request(`${APP_CONFIG.URL.email}/forgot-password?email=${email}&language=${language}`, {
            method: 'POST'
        });
    };

    sendReminder = async (properties) => {
        return FetchAdapter.request(`${APP_CONFIG.URL.email}/registration-reminder`, {
            method: 'POST',
            body: JSON.stringify(properties)
        });
    };
    generateSpvTokenLinkEmail = async (properties) => {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/email/spvToken`, {
            method: 'POST',
            body: JSON.stringify(properties)
        });
    };
}

export default new EmailService();

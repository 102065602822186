import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import RegisterReducer from "./RegisterReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import TeamReducer from "./TeamReducer";
import PlayerReducer from "./PlayerReducer";
import ClubReducer from "./ClubReducer";
import PublicReducer from "./PublicReducer";
import FeedbackReducer from "./FeedbackReducer";
import EmailReducer from "./EmailReducer";
import ResetPasswordReducer from "./ResetPasswordReducer";
import PresidentReducer from "./PresidentReducer";
import AdminReducer from "./AdminReducer";
import CoachReducer from "./CoachReducer";
import ParentReducer from "./ParentReducer";
import AthleteReducer from "./AthleteReducer";
import SportsArenaReducer from "./SportsArenaReducer";
import SubscriptionTypeReducer from "./SubscriptionTypeReducer";
import SubscriptionReducer from "./SubscriptionReducer";
import TrainingEventReducer from "./TrainingEventReducer";
import TrainingReducer from "./TrainingReducer";
import SportReducer from "./SportReducer";
import {USER_LOGGED_OUT} from "../actions/UserActions";
import CampEventReducer from "./CampEventReducer";
import CampReducer from "./CampReducer";
import CompetitionEventReducer from "./CompetitionEventReducer";
import CompetitionReducer from "./CompetitionReducer";
import AthletePaymentReducer from "./AthletePaymentReducer";
import InvoiceReducer from "./InvoiceReducer";
import ClubPaymentReducer from "./ClubPaymentReducer";
import EventExpensesReducer from "./EventExpensesReducer";
import EventPaymentReducer from "./EventPaymentReducer";
import AthleteFileReducer from "./AthleteFileReducer";
import AthleteRegisterFileReducer from "./AthleteRegisterFileReducer";
import AthleteObservationReducer from "./AthleteObservationReducer";
import ExtraPaymentNoticeReducer from "./ExtraPaymentNoticeReducer";
import ExtraPaymentReducer from "./ExtraPaymentReducer";
import ExtraNotificationReducer from "./ExtraNotificationReducer";
import StaffActivityReducer from "./StaffActivityReducer";
import IndividualTrainingReducer from "./IndividualTrainingReducer";


const RootReducer = (state, action) => {
  if (action.type === USER_LOGGED_OUT) {
    state = undefined
  }

  return AppReducer(state, action);
};

const AppReducer = combineReducers({
  login: LoginReducer,
  register: RegisterReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  player: PlayerReducer,
  club: ClubReducer,
  public: PublicReducer,
  feedback: FeedbackReducer,
  president: PresidentReducer,
  admin: AdminReducer,
  coach: CoachReducer,
  parent: ParentReducer,
  athlete: AthleteReducer,
  athleteFile: AthleteFileReducer,
  athleteRegisterFile: AthleteRegisterFileReducer,
  athleteObservation: AthleteObservationReducer,
  emailSender: EmailReducer,
  resetPassword: ResetPasswordReducer,
  sportsArena: SportsArenaReducer,
  team: TeamReducer,
  trainingEvent: TrainingEventReducer,
  training: TrainingReducer,
  individualTraining: IndividualTrainingReducer,
  campEvent: CampEventReducer,
  camp: CampReducer,
  competitionEvent: CompetitionEventReducer,
  competition: CompetitionReducer,
  subscription: SubscriptionReducer,
  subscriptionType: SubscriptionTypeReducer,
  sport: SportReducer,
  athletePayment: AthletePaymentReducer,
  invoice: InvoiceReducer,
  clubPayment: ClubPaymentReducer,
  eventExpenses: EventExpensesReducer,
  eventPayment: EventPaymentReducer,
  extraPaymentNotice: ExtraPaymentNoticeReducer,
  extraPayment: ExtraPaymentReducer,
  extraNotification: ExtraNotificationReducer,
  staffActivity: StaffActivityReducer
});

export default RootReducer;

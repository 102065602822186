import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class trainingRepositoryMobile {

    /**
     * Get trainings
     * @returns {Promise}
     */
    static getTrainings(start, end, sportId, teamId, athleteId, individual) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/mobileUpdated?start=${helperService.formatDate(start)}&end=${helperService.formatDate(end)}` +
            `${sportId != null ? '&sportId=' + sportId : ''}${teamId != null ? '&teamId=' + teamId : ''}${athleteId != null ? '&athleteId=' + athleteId : ''}${individual != null ? '&individual=' + individual : ''}`
        );
    }

    /**
     * Get training by id
     * @returns {Promise}
     */
    static getTrainingById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/training/mobile/` + id
        );
    }

    /**
     * Update training properties
     * @returns {Promise}
     */
    static updateTrainingProperties(trainingRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.training}/mobile/update`, {
            method: 'PATCH',
            body: JSON.stringify(trainingRequest)
        });
    }

    static updateUpcomingTrainingsProperties(trainingRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.training}/mobile/updateUpcomingTrainings`, {
            method: 'PATCH',
            body: JSON.stringify(trainingRequest)
        });
    }

    /**
     * Delete current training and all upcoming events
     * @returns {Promise}
     */
    static deleteUpcomingTrainings (training) {
        return FetchAdapter.request(`${APP_CONFIG.URL.training}/mobile/upcomingTrainings`, {
            method: 'DELETE',
            body: JSON.stringify(training)
        });
    }

}

import {adminRepository} from "../repositories/adminRepository";

class AdminService {

    getAdminById = (id) => {
        return adminRepository.getAdminById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAdminByAccessUserId = (id) => {
        return adminRepository.getAdminByAccessUserId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateAdmin = (admin) => {
        return adminRepository.updateAdmin(admin);
    };


    getSPVOauthLogin = () => {
        return adminRepository.getSPVOauthLogin()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}

export default new AdminService();

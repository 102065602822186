import SignIn from "./SignIn";
import SignUp from "./SignUp";

import NotFound from "./NotFound";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ClubAdd from "./ClubAdd";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Presentation from "./Presentation";
import UserManual from "./UserManual";
import OnlinePaymentsTutorial from "./OnlinePaymentsTutorial";
import ConnectSpvToken from "./ConnectSpvToken";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const sessionRoutes = [
  {
    path: "/register/user/:token/:language",
    component: SignUp,
    settings
  },
  {
    path: "/reset-password/:token",
    component: ResetPassword,
    settings
  },
  {
    path: "/session/signin",
    component: SignIn,
    settings
  },
  {
    path: "/session/forgot-password",
    component: ForgotPassword,
    settings
  },
  {
    path: "/spvLinkToken/:token",
    component: ConnectSpvToken,
    settings
  },
  {
    path: "/session/club/add",
    component: ClubAdd,
    settings
  },
  {
    path: "/session/terms/:language",
    component: Terms,
    settings
  },
  {
    path: "/session/user/manual/:language",
    component: UserManual,
    settings
  },
  {
    path: "/session/online/payment/tutorial",
    component: OnlinePaymentsTutorial,
    settings
  },
  {
    path: "/session/privacy/:language",
    component: Privacy,
    settings
  },
  {
    path: "/session/presentation/:language",
    component: Presentation,
    settings
  },
  {
    path: "/session/404",
    component: NotFound,
    settings
  }
];

export default sessionRoutes;

import { EgretLoadable } from "../../../egret";
import {userUtilsService} from "../../shared/services/userUtilsService";

const ClubEInvoice = EgretLoadable({
    loader: () => import("./ClubEInvoice")
});


const clubEInvoiceRoutes = [
    {
        path: "/club/eInvoice",
        component: ClubEInvoice,
        exact: true,
        auth: userUtilsService.getClubPresidentManagerRoles()
    }
];

export default clubEInvoiceRoutes;
import {
  ERASE_MESSAGE, GET_INDIVIDUAL_TRAINING_LIST,
  INDIVIDUAL_TRAINING_LOADING, SAVE_ERROR, SAVE_SUCCESS
} from "../actions/IndividualTrainingActions";

const initialState = {
  trainingList: [],
  loading: false,
  success: null,
  error: null
};

const IndividualTrainingReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_INDIVIDUAL_TRAINING_LIST: {
      return {
        ...state,
        trainingList: action.data
      };
    }
    case INDIVIDUAL_TRAINING_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        success: action.data,
        loading: false,
        error: null
      };
    }
    case SAVE_ERROR: {
      return {
        ...state,
        success: null,
        loading: false,
        error: action.data
      };
    }
    case ERASE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return state;
    }
  }
};

export default IndividualTrainingReducer;

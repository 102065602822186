import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";

export class spvTokenRepository {

    /**
     * Get spv token E-Invoice
     * @returns {Promise}
     */
    static getSpvToken() {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/spvToken`
        );
    }
    /**
     * Connect to spv
     * @returns {Promise}
     */
    static handleLoginConnectSpv(link) {
        return FetchAdapter.request(`${APP_CONFIG.URL.auth}/linkToken/login`, {
            method: 'POST',
            headers: {
                'Authorization': null,
                'Content-Type': 'text/plain'
            },
            body: link
        });
    }
    /**
     * Verify spv token validity E-Invoice
     * @returns {Promise}
     */
    static verifyValidToken() {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/spv/verifyValidToken`
        );
    }
    /**
     * Connect to spv with ownToken
     * @returns {Promise}
     */
    static handleLoginConnectSpvWithOwnToken() {
        return FetchAdapter.request(`${APP_CONFIG.URL.root}/spv/login`, {
            method: 'POST',
            headers: {
                'Authorization': null,
                'Content-Type': 'text/plain'
            }
        });
    }
}

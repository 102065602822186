import React, {Component} from "react";
import {
    Card, CircularProgress, Dialog,
    Icon,
    IconButton, LinearProgress,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField,
    withStyles
} from "@material-ui/core";
import shortid from "shortid";
import {connect} from "react-redux";
import CurrentUser from "../../shared/models/CurrentUser";
import {withTranslation} from "react-i18next";
import {helperService} from "../../shared/services/helperService";
import Button from "@material-ui/core/Button";
import {Breadcrumb} from "../../../egret";
import {withRouter} from "react-router";
import FormControl from "@material-ui/core/FormControl";
import {userUtilsService} from "../../shared/services/userUtilsService";
import sportServiceMobile from "../../shared/services/sportServiceMobile";
import cloneDeep from 'lodash/cloneDeep';
import UpdateSportDialog from "./UpdateSportDialog";
import {currentUserService} from "../../shared/services/currentUserService";
import DeletePlanDialog from "../calendars/dialogs/DeletePlanDialog";
import documentService from "../../shared/services/documentService";
import {EVisibleForUsersTranslated} from "../../shared/enums/EVisibleForUsers";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Document from "../../shared/models/club/Document";
import CustomizedSnackbar from "../../shared/components/CustomizedSnackbar";
import i18n from "../../../i18n";

const styles = () => ({
    observation: {
        padding: '16px',
        cursor: 'pointer',
        '&:hover':{
            background: 'rgba(0, 0, 0, 0.02)'
        }
    },
    authorName: {
        fontSize: '17px' ,
        margin: '16px 0'
    },
    observationMessage: {
        color: 'rgba(0, 0, 0, 0.54)',
        maxHeight: '500px'
    }
});

const rolesWithPermissions = userUtilsService.getClubStaffRoles();

class Documents extends Component {
    state = {
        query: {
            'authorRole': 'All'
        },
        documentList: [],
        allSports: [],
        originalDocumentList: [],
        displaySportSelector: false,
        selectedDocument: null,
        shouldOpenDeleteDialog: false,
        selectedSport: 'All',
        displayAddText: false,
        comment: null,
        displayModifyText: false,
        currentDocument: {},
        currentComment: null,
        link: null,
        currentLink: null,
        eraseLimitUploadMessage: null,
        limitUploadMessage: ''
    };

    componentDidMount() {
        const currentUser = new CurrentUser(this.props.user);
        this.setState({
            ...this.state,
            currentUser
        }, () => {
            // this.getAthletes();
            this.updatePageData();
            this.getAllSportsForClub();
        });
    }

    handleSelectChange = event => {
        event.persist();
        const query = {...this.state.query};
        if(event.target.name === 'sportId') {
            query[event.target.name] = event.target.value.id ? event.target.value.id : event.target.value;
            this.setState({
                ...this.state,
                query,
                selectedSport: event.target.value
            }, () => this.updatePageData());
        } else {
            query[event.target.name] = event.target.value;
            this.setState({query}, () => this.updatePageData());
        }
    };

    updatePageData = () => {
        const query = {...this.state.query};
        Object.keys(query).forEach(
            (key) => (query[key] === '' || query[key] === 'All') && delete query[key]
        );
        query['clubId'] = this.state.currentUser.club.id;
        if(currentUserService.isCoach(this.state.currentUser)
            || currentUserService.isMainCoach(this.state.currentUser)
        ) {
            query['sportId'] = this.props.user.sport.id;
        }

        this.setState({
            ...this.state,
            documentsLoading: true,
            counterLoading: true
        });
        if (currentUserService.isParentAthlete(this.state.currentUser)) {
            let docList = []
            documentService.getDocuments(query).then((documents) => {
                documents.map(document => {
                    if(document.visibleForUsers === "ATHLETES_PARENTS" || document.visibleForUsers === "ALL" ) {
                        if(currentUserService.isAthlete(this.state.currentUser) && this.getSportInDocument(this.state.currentUser.sport.name, document) === 1)
                            docList.push(document)
                        else if(currentUserService.isParent(this.state.currentUser)){
                            docList.push(document)
                        }
                    }
                });
                this.setState({
                    ...this.state,
                    documentsLoading: false,
                    counterLoading: false,
                    documentList: docList.sort((a,b) =>  b.lastModified - a.lastModified),
                    originalDocumentList: cloneDeep(docList)
                });
            }).catch(() => {
                this.setState({
                    ...this.state,
                    trainingPlansLoading: false,
                    counterLoading: false
                });
            });
        }
        else {
            documentService.getDocuments(query).then((documents) => {
                this.setState({
                    ...this.state,
                    documentsLoading: false,
                    counterLoading: false,
                    documentList: documents.sort((a,b) =>  b.lastModified - a.lastModified),
                    originalDocumentList: cloneDeep(documents)
                });
            }).catch(() => {
                this.setState({
                    ...this.state,
                    trainingPlansLoading: false,
                    counterLoading: false
                });
            });
        }
    };

    getAllSportsForClub = () => {
        sportServiceMobile.getSportsByClubId(this.state.currentUser.club.id).then(res => {
            this.setState({
                ...this.state,
                allSports: res
            })
        });
    };

    getRoleName = (role) => {
        const {t} = this.props;
        return userUtilsService.getRoleName(role, t);
    };

    handleAdd = (file) => {
        documentService.addDocumentFile(file, this.state.currentUser.club.id).then(() => {
            this.updatePageData();
        })
            .catch((error) => {
                this.setState({...this.state,
                            limitUploadMessage: i18n.t('office.doc.limitUpload'),
                            eraseLimitUploadMessage : true})
            });
    };
    handleAddWithComment = (comment, link) => {
        const document = new Document({ comment: comment, link: link});
        documentService.addDocumentComment(document, this.state.currentUser.club.id).then(() => {
            this.updatePageData();
        });
        this.setState({
            ...this.state,
            displayAddText: false
        });
    };
    handleModifyComment = (document,comment, link) => {
        document.comment = comment
        document.link = link
        this.setState({
            ...this.state,
            documentsLoading: true,
            counterLoading: true,
            displayModifyText: false
        }, () => {
            documentService.updateDocument(document).then(() => {
                this.updatePageData();
            });
        });
    };

    isAddButtonDisplayed = () => {
        const {currentUser} = this.state;
        return currentUser && rolesWithPermissions.find(role => currentUser.hasRole(role));
    };

    eraseFilters = () => {
        const query = {
            ...this.state.query,
            'authorRole': 'All',
            'sportId': 'All'
        };
        this.setState({
            ...this.state,
            query,
            selectedSport: 'All'
        }, () => {
            this.updatePageData();
        });
    };

    getDate = (date) => {
        return date ? helperService.formatDate(date) : '';
    };

    handleViewClick = id => {
        window.open(`/office/documents/pdf/${id}`, "_blank");
    };
    handleViewComment = (comment, link) => {
        this.setState({
            ...this.state,
            displayModifyText: false,
            currentComment: comment,
            currentLink: link
        });
    };

    handleDelete = () => {
        this.setState({
            ...this.state,
            documentsLoading: true,
            counterLoading: true,
            shouldOpenDeleteDialog: false
        }, () => {
            documentService.deleteDocumentFile(this.state.selectedDocument.id).then(() => {
                this.updatePageData();
            })
        });
    };

    saveModifiedDocuments = (document) => {
        this.setState({
            ...this.state,
            documentsLoading: true,
            counterLoading: true,
            displaySportSelector: false
        }, () => {
            documentService.updateDocument(document).then(() => {
                this.updatePageData();
            });
        });
    };

    handleDialogClose = () => {
        this.setState({
            ...this.state,
            displaySportSelector: false
        })
    };

    getSportNamesForDocument = (document) => {
        let sportNames = '';
        document.visibleForSportIds.map(sportId => {
            let sport = this.state.allSports.filter(sport => sport.id === sportId)[0];
            if(sport) {
                sportNames += sport.name[this.props.i18n.language] + ", ";
            }
        });
        sportNames = sportNames.slice(0, -2);
        if(sportNames.length > 70) {
            sportNames = sportNames.substring(0,70) + "...";
        }
        return sportNames;
    };
    getSportInDocument = (sportUser, document) => {
        let ok = 0
        document.visibleForSportIds.map(sportId => {
            let sport = this.state.allSports.filter(sport => sport.id === sportId)[0];
            if(sport.name[this.props.i18n.language] === sportUser[this.props.i18n.language]) {
              ok = 1
            }
        });
        return ok
    };

    getVisibleForUsers = (document, t) => {
        return t(EVisibleForUsersTranslated[document.visibleForUsers])
    }
    showTextDialog = () => {
        this.setState({
            ...this.state,
            displayAddText: true,
            comment: '',
            link: ''
        });
    };
    handleChangeComment = (event) => {
        this.setState({
            ...this.state,
            comment: event.target.value
        });
    }
    handleChangeLink = (event) => {
        this.setState({
            ...this.state,
            link: event.target.value
        });
    }
    render() {

        const {t, classes, i18n} = this.props;

        return (
            <div className="m-sm-30">
                <div  className="mb-sm-30">
                    <Breadcrumb routeSegments={[{ name: t('office.doc.title') }]} />
                </div>
                <div className="mx-30">
                    {currentUserService.isClubStaff(this.state.currentUser) && (
                    <h4  className="m-0 text-black">{t('office.title')} </h4> )}
                    <br/>
                    <Card className="w-100 overflow-auto" elevation={6}>
                        <Table style={{minWidth: "750px" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <div className="flex w-100">
                                            <div className="flex flex-start">
                                                {this.isAddButtonDisplayed() &&
                                                <div className="pt-30 pb-20 pl-20" style={{width:'350px'}}>
                                                    <Button variant="contained"
                                                            color="secondary"
                                                            component="label"
                                                            style={{marginRight: "20px"}}
                                                    >
                                                        {t('office.doc.labels.add')}
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => {this.handleAdd(e.target.files[0])}}
                                                            accept="application/pdf"
                                                        />
                                                    </Button>
                                                    <Button variant="contained"
                                                            color="secondary"
                                                            component="label"
                                                            onClick = { () => this.showTextDialog() }
                                                    >
                                                        {t('office.doc.labels.addLink')}
                                                    </Button>
                                                </div>
                                                }
                                                <FormControl className="pb-20 pl-20" style={{minWidth: '200px'}}>
                                                    <div className="flex flex-middle mb-8">
                                                        {t('office.doc.authorRole')}
                                                    </div>
                                                    <TextField
                                                        variant="outlined"
                                                        name="authorRole"
                                                        placeholder={t('form.role')}
                                                        value = {this.state.query["authorRole"]}
                                                        className="select"
                                                        select
                                                        onChange={this.handleSelectChange}
                                                        InputProps={{
                                                            classes: {
                                                                notchedOutline: this.state.query["authorRole"] && this.state.query["authorRole"] !== 'All' ? classes.notchedOutline : ''
                                                            },
                                                            style: {
                                                                marginRight: '12px',
                                                                height: '35px'
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem value="All">
                                                            {t('table.head.options.all')}
                                                        </MenuItem>
                                                        {rolesWithPermissions.map((role, index) => (
                                                            <MenuItem value={role} key={index}>
                                                                {this.getRoleName(role)}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                                {currentUserService.isPresidentManager(this.state.currentUser) && (
                                                    <FormControl className="pb-20 pl-20" style={{minWidth: '200px'}}>
                                                        <div className="flex flex-middle mb-8">
                                                            {t('office.doc.authorSport')}
                                                        </div>
                                                        <TextField
                                                            variant="outlined"
                                                            name="sportId"
                                                            placeholder={t('form.role')}
                                                            value = {this.state.selectedSport}
                                                            className="select"
                                                            select
                                                            onChange={this.handleSelectChange}
                                                            InputProps={{
                                                                classes: {
                                                                    notchedOutline: this.state.query["sportId"] && this.state.query["sportId"] !== 'All' ? classes.notchedOutline : ''
                                                                },
                                                                style: {
                                                                    marginRight: '12px',
                                                                    height: '35px'
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem value="All">
                                                                {t('table.head.options.all')}
                                                            </MenuItem>
                                                            {this.state.allSports.map((sport, index) => (
                                                                <MenuItem
                                                                    value={sport} key={index}>{sport.name[i18n.language]}</MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </FormControl>
                                                )}
                                                <IconButton
                                                    className="my-20"
                                                    title={t('table.labels.eraseFilters')}
                                                    onClick={() => this.eraseFilters()}>
                                                    <Icon color="error">close</Icon>
                                                </IconButton>
                                            </div>
                                            <div className="flex flex-column flex-start" style={{width: '90%'}}/>
                                            <div className="flex flex-column flex-end">
                                                <span style={{padding: "20px 40px"}}>
                                                { this.state.counterLoading &&  (<span><CircularProgress /> </span>)}
                                                    { !this.state.counterLoading &&  (
                                                        <span
                                                            style={{fontWeight: 'bold', color:'#941bcc', fontSize: '25px'}}
                                                        >
                                                        { this.state.documentList.length > 0 ? this.state.documentList.length : 0}
                                                    </span>
                                                    )}
                                            </span>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <tr><td colSpan="1"> { this.state.documentsLoading && <LinearProgress />}</td></tr>
                            </TableHead>
                            <TableBody>
                                {this.state.documentList.length > 0 ? this.state.documentList
                                    .map((document, index) =>
                                        (<TableRow key={shortid.generate()} style ={ index % 2? { background : "#fdffe0" }:{ background : "white" }}>
                                            {/*{(currentUserService.isParent(this.state.currentUser) || currentUserService.isAthlete(this.state.currentUser)) && this.getSportInDocument(this.state.currentUser.sport.name, document) === 1 &&*/}
                                                {currentUserService.isAthlete(this.state.currentUser) && this.getSportInDocument(this.state.currentUser.sport.name, document) === 1 &&
                                            (<TableCell className={classes.observation} onClick={() => { document.fileName && this.handleViewClick(document.id)}}>
                                                <div className="flex w-100">
                                                    <div className="flex flex-column flex-start" style={{width: '100%'}}>
                                                        {document.comment && (<div className="mx-16 mt-8 font-size-18">{document.comment}</div>)}
                                                        {document.link && (document.link.includes("https://") ? <p className="mx-16 mt-8 font-size-18"><a href={`${document.link}`} rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>{document.link}</a></p> : document.link.includes("http://") ? <p className="mx-16 mt-8 font-size-18"><a href={`${document.link}`} rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>{document.link}</a></p> : <p className="mx-16 mt-8 font-size-18"><a href={`http://${document.link}`} rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>{document.link}</a></p>)}
                                                        {document.fileName && (<div className="mx-16 mt-8 font-size-18">{document.fileName}.{document.extension}</div>)}
                                                        <div className="mx-16 font-size-13">
                                                            <a className="text-primary"
                                                               style={{cursor:'pointer'}}
                                                            >
                                                                <span>{document.createdBy.userFullName}</span>
                                                            </a>
                                                            ,
                                                            <span>{' ' + (document.creatorRole ? this.getRoleName(document.creatorRole) : '')}</span>
                                                        </div>
                                                        <div className="text-muted mx-16 mt-2 font-size-13">
                                                            {document.created && <span>{t('athleteFile.observations.createdDate')} {this.getDate(document.created)}</span>}
                                                            {document.lastModified && <span>{', '}{t('athleteFile.observations.modifiedDate')} {this.getDate(document.lastModified)}</span>}
                                                        </div>
                                                        <div className="mx-16 mt-2 font-size-13">
                                                            <span className="font-size-13">{t('office.doc.visibleForSports')}</span>
                                                            <span className="text-muted font-size-13">{this.getSportNamesForDocument(document)}</span>
                                                        </div>
                                                        <div className="mx-16 mt-2 font-size-13">
                                                            <span className="font-size-13">{t('office.doc.visibleForUsers')}</span>
                                                            <span className="text-muted font-size-13">{this.getVisibleForUsers(document, t)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row flex-end mt-12">
                                                        {(!document.comment && !document.link) && (<span title={t('actions.view')}>
                                                            <IconButton
                                                                className="text-primary"
                                                                onClick={() => this.handleViewClick(document.id)}>
                                                                <Icon>visibility</Icon>
                                                            </IconButton>
                                                        </span>
                                                        )}
                                                        {(document.createdBy.userEmail === this.props.user.email
                                                            || currentUserService.isClubManager(this.state.currentUser)
                                                            || currentUserService.isPresident(this.state.currentUser)
                                                        ) && (
                                                            <span title={t('actions.delete')}>
                                                            <IconButton
                                                                style={{color: "red", pointerEvents: 'auto'}}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    this.setState({
                                                                        ...this.state,
                                                                        shouldOpenDeleteDialog: true,
                                                                        selectedDocument: document
                                                                    })
                                                                }}>
                                                                <Icon>delete</Icon>
                                                            </IconButton>
                                                        </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </TableCell>) }
                                                {!currentUserService.isAthlete(this.state.currentUser) &&
                                                    (<TableCell className={classes.observation} onClick={() => { document.fileName && this.handleViewClick(document.id)}}>
                                                     {/*(<TableCell className={classes.observation} onClick={() => {document.comment ? this.handleViewComment(document.comment) : this.handleViewClick(document.id)}}>*/}
                                                        <div className="flex w-100">
                                                            <div className="flex flex-column flex-start" style={{width: '100%'}}>
                                                                {document.comment && (<div className="mx-16 mt-8 font-size-18">{document.comment}</div>)}
                                                                {document.link && (document.link.includes("https://") ? <p className="mx-16 mt-8 font-size-18"><a href={`${document.link}`} rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>{document.link}</a></p> : document.link.includes("http://") ? <p className="mx-16 mt-8 font-size-18"><a href={`${document.link}`} rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>{document.link}</a></p> : <p className="mx-16 mt-8 font-size-18"><a href={`http://${document.link}`} rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>{document.link}</a></p>)}
                                                                {document.fileName && (<div className="mx-16 mt-8 font-size-18">{document.fileName}.{document.extension}</div>)}
                                                                <div className="mx-16 font-size-13">
                                                                    <a className="text-primary"
                                                                       style={{cursor:'pointer'}}
                                                                    >
                                                                        <span>{document.createdBy.userFullName}</span>
                                                                    </a>
                                                                    ,
                                                                    <span>{' ' + (document.creatorRole ? this.getRoleName(document.creatorRole) : '')}</span>
                                                                </div>
                                                                <div className="text-muted mx-16 mt-2 font-size-13">
                                                                    {document.created && <span>{t('athleteFile.observations.createdDate')} {this.getDate(document.created)}</span>}
                                                                    {document.lastModified && <span>{', '}{t('athleteFile.observations.modifiedDate')} {this.getDate(document.lastModified)}</span>}
                                                                </div>
                                                                <div className="mx-16 mt-2 font-size-13">
                                                                    <span className="font-size-13">{t('office.doc.visibleForSports')}</span>
                                                                    <span className="text-muted font-size-13">{this.getSportNamesForDocument(document)}</span>
                                                                </div>
                                                                <div className="mx-16 mt-2 font-size-13">
                                                                    <span className="font-size-13">{t('office.doc.visibleForUsers')}</span>
                                                                    <span className="text-muted font-size-13">{this.getVisibleForUsers(document, t)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-row flex-end mt-12">
                                                                {(currentUserService.isPresident(this.state.currentUser) || currentUserService.isClubManager(this.state.currentUser))  && (
                                                                    <span title={t('actions.addSports')}>
                                                            <IconButton
                                                                className="text-primary"
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    this.setState({
                                                                        ...this.state,
                                                                        displaySportSelector: true,
                                                                        selectedDocument: document
                                                                    })
                                                                }}>
                                                                <Icon>checklist</Icon>
                                                            </IconButton>
                                                        </span>
                                                                )}
                                                                { (!document.comment && !document.link) && (
                                                                <span title={t('actions.view')}>
                                                            <IconButton
                                                                className="text-primary"
                                                                onClick={() => this.handleViewClick(document.id)}>
                                                                <Icon>visibility</Icon>
                                                            </IconButton>
                                                        </span>
                                                                )}
                                                                {(document.createdBy.userEmail === this.props.user.email || currentUserService.isPresident(this.state.currentUser) || currentUserService.isClubManager(this.state.currentUser)) && document.comment && (
                                                                    <span title={t('actions.edit')}>
                                                            <IconButton
                                                                className="text-primary"
                                                                onClick={(event) => {event.stopPropagation(); this.setState({...this.state, displayModifyText: true, currentDocument: document, comment: document.comment, link: document.link})}}
                                                                >
                                                                <Icon>edit</Icon>
                                                            </IconButton>
                                                        </span>
                                                                )}
                                                                {(document.createdBy.userEmail === this.props.user.email
                                                                    || currentUserService.isClubManager(this.state.currentUser)
                                                                    || currentUserService.isPresident(this.state.currentUser)
                                                                ) && (
                                                                    <span title={t('actions.delete')}>
                                                            <IconButton
                                                                style={{color: "red", pointerEvents: 'auto'}}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    this.setState({
                                                                        ...this.state,
                                                                        shouldOpenDeleteDialog: true,
                                                                        selectedDocument: document
                                                                    })
                                                                }}>
                                                                <Icon>delete</Icon>
                                                            </IconButton>
                                                        </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </TableCell>)}
                                        </TableRow>
                                    )
                                ) : <TableRow/>}
                            </TableBody>
                        </Table>
                    </Card>
                    {this.state.eraseLimitUploadMessage &&
                        <CustomizedSnackbar message={this.state.limitUploadMessage} severity="error" onClose={() => this.setState({
                            ...this.state, eraseLimitUploadMessage: null
                        })}/>}
                    {this.state.displaySportSelector && (
                        <UpdateSportDialog
                            open = {this.state.displaySportSelector}
                            handleClose = {() => this.handleDialogClose()}
                            goBack = {() => {}}
                            document = {this.state.selectedDocument}
                            allSports = {this.state.allSports}
                            allTeams={null}
                            handleSave = {(document) => {
                                this.saveModifiedDocuments(document);
                            }}
                        />
                    )}
                    {this.state.shouldOpenDeleteDialog && (
                        <DeletePlanDialog
                            open={this.state.shouldOpenDeleteDialog}
                            handleClose={() => {
                                this.setState({
                                    ...this.state,
                                    shouldOpenDeleteDialog: false
                                })
                            }}
                            onYesClick={() => {this.handleDelete()}}
                            title = {t('office.confirmation.title')}
                            content = {t('office.confirmation.delete')}
                        />
                    )}
                    { this.state.displayAddText && (
                                <Dialog onClose={() => {this.setState({...this.state, displayAddText: false})}} open={this.state.displayAddText} fullWidth={true} maxWidth="sm">
                                    <div className="flex flex-space-between flex-middle pl-16 pr-8 py-8 bg-primary">
                                        <h4 className="m-0 text-white">{t('office.doc.labels.addLink')}</h4>
                                        <IconButton onClick={() => {this.setState({...this.state, displayAddText: false})}}>
                                            <Icon className="text-white">clear</Icon>
                                        </IconButton>
                                    </div>
                                    <div className="p-16">
                                        <ValidatorForm onSubmit={() =>  this.handleAddWithComment(this.state.comment, this.state.link)}>
                                        <TextValidator
                                            className="mb-16 w-100"
                                            label={t("office.doc.labels.addText")}
                                            onChange={this.handleChangeComment}
                                            type="text"
                                            name="comment"
                                            value={this.state.comment || ''}
                                            validators={[
                                                "required"
                                            ]}
                                            fullWidth
                                            multiline={true}
                                            rows={3}
                                            rowsMax={3}
                                            errorMessages={[t('validation.error.required')]}
                                            id="comment"
                                        />
                                            <TextValidator
                                                className="mb-16 w-100"
                                                label={t("office.doc.labels.addLink")}
                                                onChange={this.handleChangeLink}
                                                type="text"
                                                name="link"
                                                value={this.state.link || ''}
                                                validators={[
                                                    "required"
                                                ]}
                                                fullWidth
                                                multiline={true}
                                                rows={2}
                                                rowsMax={2}
                                                errorMessages={[t('validation.error.required')]}
                                                id="link"
                                            />
                                        <div className="flex flex-space-between pt-8">
                                            <Button
                                                type="submit" variant="contained" style={{background: "#417505", color: "white"}}>
                                                {i18n.t('form.actions.save')}
                                            </Button>
                                            <Button
                                                onClick={() => {this.setState({...this.state, displayAddText: false, comment: '', link: ''})}}
                                                variant="contained"
                                                style={{background: "#D0021B", color: "white"}}
                                            >
                                                {i18n.t('athleteFile.observations.labels.cancel')}
                                            </Button>
                                        </div>
                                        </ValidatorForm>
                                    </div>
                                </Dialog>
                        )}
                    { this.state.displayModifyText && (
                        <Dialog onClose={() => {this.setState({...this.state, displayModifyText: false})}} open={this.state.displayModifyText} fullWidth={true} maxWidth="sm">
                            <div className="flex flex-space-between flex-middle pl-16 pr-8 py-8 bg-primary">
                                <h4 className="m-0 text-white">{t('office.doc.labels.edit')}</h4>
                                <IconButton onClick={() => {this.setState({...this.state, displayModifyText: false,  comment: '',  link: ''})}}>
                                    <Icon className="text-white">clear</Icon>
                                </IconButton>
                            </div>
                            <div className="p-16">
                                <ValidatorForm onSubmit={() =>  this.handleModifyComment(this.state.currentDocument, this.state.comment, this.state.link)}>
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label={t("office.doc.labels.edit")}
                                        onChange={this.handleChangeComment}
                                        type="text"
                                        name="comment"
                                        value={this.state.comment || ''}
                                        validators={[
                                            "required"
                                        ]}
                                        fullWidth
                                        multiline={true}
                                        rows={3}
                                        rowsMax={3}
                                        errorMessages={[t('validation.error.required')]}
                                        id="comment"
                                    />
                                    <TextValidator
                                        className="mb-16 w-100"
                                        label={t("office.doc.labels.addLink")}
                                        onChange={this.handleChangeLink}
                                        type="text"
                                        name="link"
                                        value={this.state.link || ''}
                                        validators={[
                                            "required"
                                        ]}
                                        fullWidth
                                        multiline={true}
                                        rows={2}
                                        rowsMax={2}
                                        errorMessages={[t('validation.error.required')]}
                                        id="link"
                                    />
                                    <div className="flex flex-space-between pt-8">
                                        <Button
                                            type="submit" variant="contained" style={{background: "#417505", color: "white"}}>
                                            {i18n.t('form.actions.save')}
                                        </Button>
                                        <Button
                                            onClick={() => {this.setState({...this.state, displayModifyText: false, comment: '', link: ''})}}
                                            variant="contained"
                                            style={{background: "#D0021B", color: "white"}}
                                        >
                                            {i18n.t('athleteFile.observations.labels.cancel')}
                                        </Button>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </Dialog>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    athleteObservation: state.athleteObservation,
    user: state.user
});

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            {  }
        )(withTranslation()(Documents))
    )
);

import {invoiceRepository} from "../repositories/invoiceRepository";

class InvoiceService {

    getInvoices = (query) => {
        return invoiceRepository.getInvoices(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getInvoiceById = (id) => {
        return invoiceRepository.getInvoiceById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    deleteInvoice = (invoice) => {
        return invoiceRepository.deleteInvoice(invoice);
    };

    generateInvoices = (params) => {
        return invoiceRepository.generateInvoices(params);
    };

    downloadAllInvoices = (query) => {
     return invoiceRepository.downloadAllInvoices(query)
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
    };

    getInvoicesCount = (query) => {
                return invoiceRepository.getInvoicesCount(query)
                    .then((res) => {
                        return res;
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            };

    getInvoicesStats = (query) => {
        return invoiceRepository.getInvoicesStats(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    getInvoiceLanguage = (clubId) => {
        return invoiceRepository.getInvoiceLanguage(clubId)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    cancelInvoice = (invoice) => {
        return invoiceRepository.cancelInvoice(invoice);
    };
    generateEFactura = (id) => {
        return invoiceRepository.generateEFactura(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    uploadEFactura = (id) => {
        return invoiceRepository.uploadEFactura(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    getStareMesajEFactura = (id) => {
        return invoiceRepository.getStareMesajEFactura(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    deleteEFactura = (id) => {
        return invoiceRepository.deleteEFactura(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    generateEFacturaClub = (id, paymentCategory) => {
        return invoiceRepository.generateEFacturaClub(id, paymentCategory)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    uploadEFacturaClub = (id, paymentCategory) => {
        return invoiceRepository.uploadEFacturaClub(id, paymentCategory)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    getStareMesajClub = (id, paymentCategory) => {
        return invoiceRepository.getStareMesajClub(id, paymentCategory)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    deleteEFacturaClub = (id, paymentCategory) => {
        return invoiceRepository.deleteEFacturaClub(id, paymentCategory)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}

export default new InvoiceService();

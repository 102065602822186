import React, { Component } from "react";
import {NavLink} from "react-router-dom";
import { Icon } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import EgretVerticalNavExpansionPanel from "./EgretVerticalNavExpansionPanel";
import { withStyles } from "@material-ui/styles";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {authConditions} from "../../../app/auth/authConditions";
import CurrentUser from "../../../app/shared/models/CurrentUser";
import {authRoles} from "../../../app/auth/authRoles";
import Badge from "@material-ui/core/Badge";
import {getUnreadObservationsCount} from "../../../app/redux/actions/AthleteObservationActions";
import athleteFileService from "../../../app/shared/services/athleteFileService";
import athleteService from "../../../app/shared/services/athleteService";
import athleteRegisterFileService from "../../../app/shared/services/athleteRegisterFileService";

const styles = theme => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)"
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)"
  }
});

class EgretVerticalNav extends Component {
  state = {
    collapsed: true
  };

  componentDidMount() {
    const currentUser = new CurrentUser(this.props.user);
    if (currentUser && currentUser.hasRole(authRoles.athlete)) {
      athleteService.getAthleteByAccessUserId(currentUser.id).then( (athlete) => {
        athleteFileService.getAthleteFileByAthleteId(athlete.id).then((athleteFile) => {
          this.setState({
            ...this.state,
            currentUser,
            athleteFileId: athleteFile.id
          }, () => {
            this.getUnreadObservations();
          });
        });
      });
      athleteService.getAthleteByAccessUserId(currentUser.id).then( (athlete) => {
        athleteRegisterFileService.getAthleteRegisterFileByAthleteId(athlete.id).then((athleteRegisterFile) => {
          this.setState({
            ...this.state,
            currentUser,
            athleteRegisterFileId: athleteRegisterFile ? athleteRegisterFile.id : null
          }, () => {
            this.getUnreadObservations();
          });
        });
      });
    } else {
      this.setState({
        ...this.state,
        currentUser
      }, () => {
        this.getUnreadObservations();
      });
    }

  }

  getUnreadObservations = () => {
    const {currentUser} = this.state;
    if (currentUser && (currentUser.hasRole(authRoles.parent) || currentUser.hasRole(authRoles.athlete))) {
      this.props.getUnreadObservationsCount();
    }
  };

  getPath = (item) => {
    const {user} = this.props;
    const path = item.path;
    const athleteFileId = this.state.athleteFileId;
    const athleteRegisterFileId = this.state.athleteRegisterFileId;
    if (path.includes('id')) {
      if (path.includes('club') && user.club !== null) {
        return  path.replace('id', user.club.id);
      } else if (path.includes('profile')) {
        return path.replace('id', user.userDetailsId);
      } else if (path.includes('sportfiles')) {
          return path.replace('id', athleteFileId);
      } else if (path.includes('officeTasks')) {
        return path.replace('id', athleteRegisterFileId);
      }
    }
    return path;
  };

  shouldDisplayTotalUnreadObservations = (itemName) => {
    const {currentUser} = this.state;
    return (itemName === 'menu.sportFiles.athletes' || itemName === 'menu.office.tasks' ) && (currentUser && (currentUser.hasRole(authRoles.parent) || currentUser.hasRole(authRoles.athlete)));
  };

  renderLevels = (data, type) => {
    const {user, t} = this.props;
    return data.map((item, index) => {
      const authorized =
          item.auth
              ?  (user && user.role ? item.auth.includes(user.role)  : false)
              : true;
      let conditioned = false;
      if(item.conditions && item.conditions.includes(authConditions.fundedClub) && user.club && user.club.stateFunded) {
        conditioned = true;
      }
      else if(item.conditions && item.conditions.includes(authConditions.hideFinancial) && user && user.hideFinancial) {
        conditioned = true;
      }
      else if(item.conditions && item.conditions.includes(authConditions.invoiceActive) && user.club && !user.club.invoiceActive) {
        conditioned = true;
      }

      if (authorized && !conditioned) {
        if (item.children) {
          return (
              <EgretVerticalNavExpansionPanel item={item} key={index}>
                {this.renderLevels(item.children, 'child')}
              </EgretVerticalNavExpansionPanel>
          );
        } else {
          return (
              <NavLink key={index} to={this.getPath(item)} className="nav-item">
                <TouchRipple key={item.name} name="child" className="w-100" style={type === 'child' ? {backgroundColor: '#512E5F'} : {}}>
                  {(() => {
                    if (item.icon) {
                      if (type === 'child') {
                        return (
                            <div style={{position: 'relative', 'left': '35px', 'top': '2px'}}>
                                <Icon className="item-icon text-middle bg-light-primary">{item.icon}</Icon>
                            </div>
                        );
                      } else {
                        return (
                            <Icon className="item-icon text-middle">{item.icon}</Icon>
                        );
                      }
                    } else {
                      return (
                          <span className="item-icon icon-text">{item.iconText}</span>
                      );
                    }
                  })()}
                  {(() => {
                    if (type === 'child') {
                      if (this.shouldDisplayTotalUnreadObservations(item.name)) {
                        return (
                          <span
                            style={{position: 'relative', 'left': '35px', 'top': '2px'}}
                            className="text-middle pl-20 item-text">
                              {t(item.name)}{this.props.athleteObservation.unreadObservations !== 0 ? ` (${this.props.athleteObservation.unreadObservations}) ` : ''}
                          </span>
                        );
                      } else {
                        return (
                          <span
                              style={{position: 'relative', 'left': '35px', 'top': '2px'}}
                              className="text-middle pl-20 item-text">
                              {t(item.name)}
                          </span>
                        );
                      }
                    } else {
                      return (
                          <span className="text-middle pl-20 item-text">{t(item.name)}</span>
                      );
                    }
                  })()}
                  <div className="mx-auto"/>
                  {item.badge && (
                      <div className={`badge bg-${item.badge.color}`}>
                        {item.badge.value}
                      </div>
                  )}
                </TouchRipple>
              </NavLink>
          );
        }
      }
    });
  };

  handleClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <div className="navigation">
        {this.renderLevels(this.props.navigation, 'parent')}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  athleteObservation: state.athleteObservation
});


export default withStyles(styles)(connect(mapStateToProps, {getUnreadObservationsCount})(withTranslation()(EgretVerticalNav)));

import {spvTokenRepository} from "../repositories/spvTokenRepository";

class SpvTokenService {

    getSpvToken = () => {
        return spvTokenRepository.getSpvToken()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    handleLoginConnectSpv = (link) => {
        return spvTokenRepository.handleLoginConnectSpv(link)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    verifyValidToken = () => {
        return spvTokenRepository.verifyValidToken()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
    handleLoginConnectSpvWithOwnToken = () => {
        return spvTokenRepository.handleLoginConnectSpvWithOwnToken()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}
export default new SpvTokenService();
